import React, { useState } from 'react';
import { Button, Divider, Input, List, notification, Popconfirm } from 'antd';

export interface SaveData {
    label: string;
    data: any;
}

const SaveLoad = ({
    storageKey,
    currentData,
    setFn,
}: {
    storageKey: string,
    currentData: any,
    setFn: (data: any) => void
}) => {
    const [notificationApi, notificationContext] = notification.useNotification();
    const [savedData, setSavedData] = useState<SaveData[]>([])
    const [savedItemLabel, setSavedItemLabel] = useState<string>("")

    const saveItem = (label: string, data: any) => {
        const filtered = savedData.filter((item: SaveData) => item.label !== label)
        const newSavedData = [...filtered, { label, data }].slice(-20)
        setSavedData(newSavedData)
        localStorage.setItem(storageKey, JSON.stringify(newSavedData))
    }

    const loadItem = (label: string) => {
        const savedItem = savedData.find((item: SaveData) => item.label === label)
        if (savedItem) {
            console.log('Found saveData', savedItem)
            setFn(savedItem)
            setSavedItemLabel(savedItem.label)
            notificationApi.success({
                message: 'Loaded',
                description: `Loaded ${label}`,
            });
        }
    }

    const deleteItem = (label: string) => {
        const newSavedData = savedData.filter((item: SaveData) => item.label !== label)
        setSavedData(newSavedData)
        localStorage.setItem(storageKey, JSON.stringify(newSavedData))
    }

    // Load data on page load
    React.useEffect(() => {
        const storageSavedData = localStorage.getItem(storageKey)
        if (storageSavedData) {
            try {
                setSavedData(JSON.parse(storageSavedData))
            } catch (e: any) {
                notificationApi.error({
                    message: 'Error loading saved data',
                    description: e.message,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageKey])

    return <>
        {notificationContext}
        <Input placeholder="Label for SaveFile" defaultValue={''} value={savedItemLabel} onChange={(e) => setSavedItemLabel(e.target.value)} />
        <Button type="primary" onClick={() => saveItem(savedItemLabel, currentData)}>Save</Button>
        <Divider />
        <List
            dataSource={savedData}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        title={item.label}
                    />
                    <Popconfirm
                        title="Delete the Saved Item"
                        description={`Are you sure to delete '${item.label}'?`}
                        onConfirm={() => deleteItem(item.label)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger>Delete</Button>
                    </Popconfirm>
                    <Button type="primary" onClick={() => loadItem(item.label)}>Load</Button>
                </List.Item>
            )}
        />
    </>

}

export default SaveLoad;