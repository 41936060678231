import { useState } from 'react';
import { Input, Modal, Button, notification } from 'antd';
import { AttendanceSummary } from '../../utils/attendance';

const SaveAttendanceDashboard = ({ data }: {
    data: AttendanceSummary[]
}) => {
    const [password, setPassword] = useState('')

    const [modalOpen, setModalOpen] = useState(false)

    const [notificationApi, notificationContext] = notification.useNotification();

    const showModal = () => {
        setModalOpen(true);
    };

    const handleOk = async () => {
        try {
            const updateRes = await fetch('https://crm.natlabs.app/.netlify/functions/save-dashboard', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': password
                },
                body: JSON.stringify(data)
            })

            if (!updateRes.ok) {
                throw new Error(`Error saving attendance dashboard ${updateRes.status} ${updateRes.statusText}`)
            }

            notificationApi.success({
                message: 'Success',
                description: 'Successfully saved attendance dashboard',
                placement: 'topRight'
            })
            setModalOpen(false)
        } catch (err) {
            notificationApi.error({
                message: 'Error',
                description: `Error saving attendance dashboard ${err}`,
                placement: 'topRight'
            })
            setModalOpen(false)
            return
        }
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    return <>
        {notificationContext}
        <Button onClick={() => showModal()}>Save To Server</Button>
        <Modal title="Submit" open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input type='password' placeholder='Enter password' onChange={(e) => setPassword(e.target.value)} value={password} />
        </Modal>
    </>
}

export default SaveAttendanceDashboard;