import React from 'react';
import './App.css';
import Formatter from './Components/Formatter';
import AttendancePage from './Components/AttendancePage';
import AttendanceDashboard from './Components/Dashboard/AttendanceDashboard';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from './Components/Root';

const App = () => {
  // State
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          index: true,
          element: <AttendancePage />,
        },
        {
          path: "attendance-dashboard",
          element: <AttendanceDashboard />,
        },
        {
          path: "formatter",
          element: <Formatter />,
        },
      ]
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
