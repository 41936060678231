
// Const
const debug = false
const fmgRE = /FMG\s\w+/;
const totalRE = /total\s?\w*:\s?\d+/ig;
const presentRE = /present:\s?\d+/ig;
const absentRE = /absent/ig;

const formatStrength = (rawData) => {
  let currentFMG = ""
  let parsingAbsent = false
  let details = {}

  const dataArr = rawData.split("\n")
  const today = dataArr[0]
  const data = dataArr.slice(1, -1)
  data.forEach(line => {
    console.log(`Parsing line: ${line}`)
    if (line.includes("FMG")) {
      let matched = line.match(fmgRE)?.[0]?.split(" ")?.[1]

      debug && console.log(matched)
      if (matched !== undefined) {
        currentFMG = matched
        details[matched] = {
          total: 0,
          present: 0,
          absent: []
        }
      }
      parsingAbsent = false
      return
    }
    if (parsingAbsent) {
      if (line !== "") {
        details[currentFMG].absent.push(line)
        return
      } else {
        parsingAbsent = false
        return
      }
    }

    if (absentRE.test(line)) {
      parsingAbsent = true
      return
    }

    let present = parseNumberLine(line, 'present', presentRE)
    if (present !== undefined) {
      console.log(`Present Count for ${currentFMG}: ${present}`)
      details[currentFMG].present = present
      return
    }

    let total = parseNumberLine(line, 'total', totalRE)
    if (total !== undefined) {
      console.log(`Total Count for ${currentFMG}: ${total}`)
      details[currentFMG].total = total
      return
    }
    console.log(`=========`)
  })
  console.log(details)
  return printDetails(details, today)
}

export const parseNumberLine = (
  line,
  keyword,
  relevantRE
) => {
  let isRelevant = relevantRE.test(line)
  debug && console.log(`${keyword} "${line}": ${isRelevant}`)

  if (isRelevant) {
    debug && console.log(`Matched ${keyword} Regex: ${line}`)

    let rawNum = line.match(/\d+/)?.[0]

    if (rawNum !== undefined) {
      return parseInt(rawNum)
    }
    return
  }
}


const formatFMGStrength = (key, detail) => {
  if (detail === undefined) {
    return `${key}: ?/?`
  }
  return `${key}: ${detail.present}/${detail.total}`
}

const formatAbsentee = (line) => {
  const listRe = /^-?\s?(\d\.\s?|-\s?)?/

  return line.replace(listRe, '')
}

const printDetails = (details, today) => {
  let total = 0
  let present = 0
  let absent = []
  Object.keys(details).forEach(k => {
    total += details[k].total
    present += details[k].present

    details[k].absent.forEach(ak => {
      absent.push(`${k} - ${formatAbsentee(ak)}`)
    })
  })

  let output = `=================

Strength ${today}
Total: ${present}/${total}
Missing: ${total - present}

${formatFMGStrength('HQ', details['HQ'])}
${formatFMGStrength('A', details['A'])}
${formatFMGStrength('B', details['B'])}
${formatFMGStrength('C', details['C'])}
${formatFMGStrength('D', details['D'])}
${formatFMGStrength('E', details['E'])}
${formatFMGStrength('VC', details['VC'])}
${formatFMGStrength('Driver', details['Driver'])}

Notes(${absent.length}):
`
  absent.forEach(line => {
    output += `\n- ${line}`
  })

  output += `\n==============`
  console.log(output)
  return output
}

const TemplateText = `
2024/01/01
==================================
FMG HQ, PM strength:

Total: 16
Present: 16

Absent:

FMG Driver 

Total: 2
Present: 1

Absent:
1. AA

==================================

[1:15 pm, 13/03/2023] Lead: FMG B, PM strength:

Total: 19
Present: 18

Absent:
- AA (AA)

[1:21 pm, 13/03/2023] Lead: 13/03/2023

FMG C PM strength:

Total: 13
Present: 13
Additional +1 VC (AA)

[1:29 pm, 13/03/2023] Lead: 13/03/2023

FMG E PM strength:

Total: 15
Present: 13 (includes 1 x VC, AA)

Absent:
AA (MC)
AA (Time off)

[1:30 pm, 13/03/2023] Lead: 13/03/2023

FMG A Midday strength:

Total: 18
Present: 17 (including VC)

Absent:
- AAA (Time-off: work)

[1:31 pm, 13/03/2023] Lead: 13/03/2023

FMG D AM strength as follows:

Total strength: 16
Present:13 (MEs Included)

Absent:
1. AA(Sick)
`

export {
  formatStrength,
  TemplateText
}