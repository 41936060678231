
import { parseNumberLine } from "../utils/formatter";


const fmgRE = /FMG\s\w+/;
const totalRE = /total\s?\w*:\s?\d+/ig;
const presentRE = /present:\s?\d+/ig;
const absentRE = /absent/ig;
const remarksRE = /remarks/ig;

export interface AttendanceSummary {
    key: string;
    label: string;
    present: number;
    total: number;
    absent: string[];
    remarks: string[];
    dateTimestamp?: number;
}

export interface DashboardResponse {
    data: AttendanceSummary[]
}

const parseAttendanceSummary = (input: string): AttendanceSummary => {
    let parsing = ''
    let details: AttendanceSummary = {
        key: "",
        label: "",
        present: 0,
        total: 0,
        absent: [],
        remarks: [],
        dateTimestamp: Date.now(),
    }

    const data = input.split("\n")
    details.label = data[0]

    // Slice data to remove first line
    const slicedData = data.slice(0, data.length)

    console.log(slicedData)

    slicedData.forEach(line => {
        console.log(`Parsing line: ${line}`)
        if (line.includes("FMG")) {
            let matched = line.match(fmgRE)?.[0]?.split(" ")?.[1]

            if (matched !== undefined) {
                details.key = matched
            }
            return
        }

        if (parsing === 'absent') {
            if (line !== "") {
                details.absent.push(line)
                return
            } else {
                parsing = ""
                return
            }
        }

        if (absentRE.test(line)) {
            parsing = 'absent'
            return
        }
        if (remarksRE.test(line)) {
            parsing = 'remarks'
            return
        }

        if (parsing === 'remarks') {
            if (line !== "") {
                details.remarks.push(line)
                return
            } else {
                parsing = ""
                return
            }
        }

        let present = parseNumberLine(line, 'present', presentRE)
        if (present !== undefined) {
            details.present = present
            return
        }

        let total = parseNumberLine(line, 'total', totalRE)
        if (total !== undefined) {
            details.total = total
            return
        }
    })

    return details;
}

export {
    parseAttendanceSummary,
}