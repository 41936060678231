import React, { useState, useCallback, useEffect } from 'react'
import { Button, Input, notification, Tabs, Row, Col, Divider } from 'antd'
import { formatStrength, TemplateText } from '../utils/formatter'
import { newSaveNameByDate } from '../utils/utils'

const { TextArea } = Input

interface SavedInput {
    label: string,
    input: string
}

const Formatter = () => {
    const [setNotification, notificationContext] = notification.useNotification();
    const [inputText, setInputText] = useState<string>('')
    const [outputText, setOutputText] = useState<string>('')
    const [savedInput, setSavedInput] = useState<SavedInput[]>()


    const updateInputText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        console.log(e.target.value)
        setInputText(e.target.value)
        setOutputText(tryFormatStrength(e.target.value))
        // Save to cookies
        localStorage.setItem('formatter-inputText', e.target.value)
    }

    const copyToClipboard = (data: string | void, save: boolean) => {
        if (!data) {
            return
        }
        const el = document.createElement('textarea')
        el.value = data
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)

        setNotification['success']({
            message: 'Copied to clipboard'
        });

        if (save) {
            saveInput(newSaveNameByDate(), data)
        }
    }

    const tryFormatStrength = useCallback((input: string) => {
        try {
            return formatStrength(input)
        } catch (e: any) {
            setNotification['error']({
                message: 'Error parsing input',
                description: e.message,
            });
            return input
        }
    }, [setNotification])

    // Save and Load
    const saveInput = useCallback((label: string, input: string) => {
        const newSavedInput: SavedInput[] = [...savedInput || [], { label, input }].slice(-20)
        setSavedInput(newSavedInput)
        localStorage.setItem('formatter-savedInput', JSON.stringify(newSavedInput))
    }, [savedInput])

    const loadInput = useCallback((label: string, input: string) => {
        setInputText(input)
        setOutputText(tryFormatStrength(input))
    }, [tryFormatStrength])

    // On load, check if there is a saved input 
    useEffect(() => {
        const savedInput = localStorage.getItem('formatter-inputText')
        if (savedInput) {
            setInputText(savedInput)
            setOutputText(tryFormatStrength(savedInput))
        }

        const savedInputs = localStorage.getItem('formatter-savedInput')
        if (savedInputs) {
            setSavedInput(JSON.parse(savedInputs))
        }
    }, [tryFormatStrength])

    const tabs = [
        {
            key: 'edit',
            label: 'Edit',
            children: <TextArea rows={15} placeholder="Input" value={inputText} onChange={updateInputText} />
        },
        {
            key: 'preview',
            label: 'Preview',
            children: <TextArea rows={15} placeholder="Output" value={outputText} />
        },
    ]

    return <>
        <Row>
            <Col span={24}>
                {notificationContext}

                <Tabs
                    type="card"
                    items={tabs}
                />

            </Col>
        </Row>

        <Row>
            <Col span={24}>

                <Button type="default" onClick={() => setInputText('')}>
                    Clear
                </Button>
                {' '}
                <Button type="primary" onClick={() => copyToClipboard(outputText, true)}>
                    Copy
                </Button>
                {' '}
                <Button type="primary" onClick={() => copyToClipboard(TemplateText, false)}>
                    Template
                </Button>
            </Col>
        </Row>
        <Row style={{ paddingTop: 5 }}>
            <Col span={24}>
                <Divider />
                <Button type="primary" onClick={() => saveInput(newSaveNameByDate(), inputText)}>
                    Save
                </Button>
            </Col>
            <Col span={24}>
                <h2> Archive</h2>
                {' '}
                {savedInput && savedInput.map((input, index) => {
                    // Render list of saved inputs with button beside to load
                    return <Button key={index} type="default" onClick={() => loadInput(input.label, input.input)} style={{ marginRight: 5, marginBottom: 5 }}>
                        {input.label}
                    </Button>
                })}
            </Col>
        </Row >
    </>
}

export default Formatter
