import { Button, Card, Col, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { AttendanceSummary, parseAttendanceSummary } from "../../utils/attendance";
import LoadAttendanceButton from "./LoadAttendanceDashboard";
import SaveAttendanceDashboard from "./SaveAttendanceDashboard";
import DashboardItem from "./DashboardItem";

const AttendanceDashboard = () => {
    const saveToLocalStorage = (data: AttendanceSummary[]) => {
        localStorage.setItem('attendance-dashboard', JSON.stringify(data))
    }

    const loadFromLocalStorage = () => {
        const data = localStorage.getItem('attendance-dashboard')
        if (data === null) {
            return []
        }
        setData(JSON.parse(data))
    }

    useEffect(() => {
        loadFromLocalStorage()
    }, [])

    const [data, setData] = useState<AttendanceSummary[]>([]);

    const [currentInput, setCurrentInput] = useState<string>('');

    const updateDataWithInput = () => {
        const parsedData = parseAttendanceSummary(currentInput);
        const newData = [...data.filter(item => item.key !== parsedData.key), parsedData];
        setData(newData);
        saveToLocalStorage(newData);
    }
    const removeData = (key: string) => {
        const newData = data.filter(item => item.key !== key)
        setData(newData);
        saveToLocalStorage(newData);
    }

    const overwriteData = (key: string, newData: string) => {
        console.log(`overwriting data: ${newData}`)
        const parsedData: AttendanceSummary = JSON.parse(newData)
        const newDataArr = [...data.filter(item => item.key !== parsedData.key), parsedData];
        setData(newDataArr);
        saveToLocalStorage(newDataArr);
    }

    const consolidateDataAndExport = () => {
        const consolidatedData: AttendanceSummary = {
            key: "Consolidated",
            label: "Consolidated Strength",
            present: 0,
            total: 0,
            absent: [],
            remarks: [],
        }

        data.forEach(item => {
            consolidatedData.present += item.present
            consolidatedData.total += item.total
            consolidatedData.absent = [...consolidatedData.absent, ...item.absent.map(absentee => `[${item.key}]${absentee}`)]
            consolidatedData.remarks = [...consolidatedData.remarks, ...item.remarks.map(remark => `[${item.key}]${remark}`)]
        })

        console.log(consolidatedData)
        return `${consolidatedData.label}\nPresent: ${consolidatedData.present} / ${consolidatedData.total}\n` +
            `Absent: ${consolidatedData.total - consolidatedData.present}\n\n` +
            `${data.map(item => `${item.key}: ${item.present} / ${item.total}`).join('\n')}\n\n` +
            `Absent(${consolidatedData.absent.length}):\n${consolidatedData.absent.join('\n')}\n\nRemarks(${consolidatedData.remarks.length}):\n${consolidatedData.remarks.join('\n')}`
    }

    console.log(`data: ${JSON.stringify(data)}`)

    return (
        <div>
            <Row>
                <Col>
                    <LoadAttendanceButton setFn={(data) => {
                        setData(data)
                        saveToLocalStorage(data);
                    }} />
                    <SaveAttendanceDashboard data={data} />
                </Col>
            </Row>
            <Row>
                {data.sort((a, b) => { return a.key > b.key ? 1 : -1 }).map((item) => <Col xs={24} sm={12} md={8} xl={6} key={item.key}>
                    <DashboardItem item={item} removeData={removeData} updateData={overwriteData} />
                </Col>)}

                <Col xs={24} sm={12} md={8} xl={6}>
                    <Card>
                        <TextArea rows={10} value={consolidateDataAndExport()} />
                        <Button type="primary" onClick={() => navigator.clipboard.writeText(consolidateDataAndExport())}>Copy</Button>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} xl={6}>
                    <Card>
                        <TextArea rows={10} value={currentInput} onChange={(e) => setCurrentInput(e.target.value)} />
                        <Button onClick={() => setCurrentInput('')}>Clear</Button>
                        {' '}
                        <Button type="default" onClick={async () => {
                            const cbdata = await navigator.clipboard.readText()
                            setCurrentInput(cbdata)
                        }}>Paste</Button>
                        {' '}
                        <Button type="primary" onClick={updateDataWithInput}>Update</Button>
                    </Card>
                </Col>
            </Row>


        </div>
    );
}

export default AttendanceDashboard;