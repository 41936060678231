
const newSaveNameByDate = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const randomStr = Math.random().toString(36).substring(7)

    return `${year}-${month}-${day}:${hour}:${minute}-${randomStr}`
}

export {
    newSaveNameByDate
}