import { useState } from 'react';
import { Input, Modal, Button, notification } from 'antd';
import { AttendanceSummary, DashboardResponse } from '../../utils/attendance';

const LoadAttendanceButton = ({ setFn }: {
    setFn: (attendance: AttendanceSummary[]) => void
}) => {
    const [password, setPassword] = useState('')

    const [modalOpen, setModalOpen] = useState(false)

    const [notificationApi, notificationContext] = notification.useNotification();

    const showModal = () => {
        setModalOpen(true);
    };

    const handleOk = async () => {
        try {
            const currentList = await fetch('https://crm.natlabs.app/.netlify/functions/fetch-data?key=dashboard', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': password
                },
            })

            const jsonRes = await currentList.json()

            const result: DashboardResponse = jsonRes
            console.log(`Fetched results: ${JSON.stringify(result)}`)

            setFn(result.data)
            notificationApi.success({
                message: 'Success',
                description: 'Successfully loaded attendance dashboard',
                placement: 'topRight'
            })
            setModalOpen(false)
        } catch (err) {
            notificationApi.error({
                message: 'Error',
                description: `Error loading attendance dashboard ${err}`,
                placement: 'topRight'
            })
            setModalOpen(false)
            return
        }
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    return <>
        {notificationContext}
        <Button onClick={() => showModal()}>Load from Server</Button>
        <Modal title="Submit" open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input type='password' placeholder='Enter password' onChange={(e) => setPassword(e.target.value)} value={password} />
        </Modal>
    </>
}

export default LoadAttendanceButton;