import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import {
    TableOutlined,
    SmileOutlined,
    OneToOneOutlined,
} from '@ant-design/icons';
import { useNavigate, Outlet } from "react-router-dom";

const { Header, Content, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

type PageDataItem = {
    icon: React.ReactNode;
    label?: string;
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const Root = () => {
    const [currentTab, setCurrentTab] = useState('attendance');
    const navigate = useNavigate();

    // Functions
    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'attendance') {
            navigate('/');
            setCurrentTab('attendance');
            return;
        }
        navigate(e.key as string);
        setCurrentTab(e.key as string);
    };

    const PageData: { [key: string]: PageDataItem } = {
        'attendance': {
            icon: <SmileOutlined />,
        },
        'attendance-dashboard': {
            icon: <TableOutlined />,
            label: 'Dashboard',
        },
        'formatter': {
            icon: <OneToOneOutlined />,
        },
    };

    const titleCase = (str: string) => {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    const items: MenuItem[] = Object.entries(PageData).map(([key, value]) => {
        return getItem(value?.label || titleCase(key), key, value.icon);
    });

    return <div className="App">
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={items} onClick={onClick} />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, color: 'white' }} >{titleCase(currentTab)}</Header>
                <Content style={{ margin: '24px 16px' }}>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    </div>
}

export default Root;