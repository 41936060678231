import React, { useState } from 'react';
import { Card, Button, List, Tabs } from 'antd';
import { AttendanceSummary } from '../../utils/attendance';
import TextArea from 'antd/es/input/TextArea';


const dateNumberToDate = (dateNumber: number) => {
    // Return date in format YYYY-MM-DDTHH:MM:SSZ
    const date = new Date(dateNumber)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    // Return
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
}

const DashboardItem = ({ item, removeData, updateData }: {
    item: AttendanceSummary,
    removeData: (key: string) => void,
    updateData: (key: string, data: string) => void
}) => {
    const [currentInput, setCurrentInput] = useState<string>(JSON.stringify(item, null, 2));

    const tabs = [
        {
            label: 'View',
            key: 'view',
            children: <>
                <h2>{item.label}</h2>
                <h4>{item.key}</h4>
                <p>Present: {item.present} / {item.total}</p>
                {item.absent.length > 0 && <List
                    header={<b>Absent({item.absent.length})</b>}
                    bordered={true}
                    size="small"
                    dataSource={item.absent}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                    style={{ marginBottom: 10 }}
                />}
                {item.remarks.length > 0 && <List
                    header={<b>Remarks({item.remarks.length})</b>}
                    bordered={true}
                    size="small"
                    dataSource={item.remarks}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />}
                {item.dateTimestamp && <>Last Updated: {dateNumberToDate(item.dateTimestamp).toLocaleString()}</>}
                <br />
                <Button onClick={() => removeData(item.key)}>Remove</Button>
            </>
        },
        {
            label: 'Edit',
            key: 'edit',
            children: <>
                <TextArea value={currentInput} onChange={(e) => { setCurrentInput(e.target.value) }} rows={15} />
                <Button type='primary' onClick={() => { updateData(item.key, currentInput) }}>Save</Button>
            </>
        }
    ]

    return <Card>
        <Tabs defaultActiveKey="view" tabPosition="top" items={tabs} />
    </Card>
}

export default DashboardItem;